import React from 'react';
import { Alert, Button, Form, Icon, Input } from 'antd';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sending: false,
      send: false,
      info: null
    };
  }

  componentDidMount() {
    this.props.form.validateFields();
  }

  handleSubmit = event => {
    event.preventDefault();

    let data = {
      fullname: this.props.form.getFieldValue('fullname'),
      email: this.props.form.getFieldValue('email'),
      phone: this.props.form.getFieldValue('phone'),
      message: this.props.form.getFieldValue('message')
    };

    this.setState({
      sending: true
    });

    fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          this.props.form.setFieldsValue({
            fullname: '',
            email: '',
            phone: '',
            message: ''
          });

          this.props.form.validateFields();

          this.setState({
            sending: false,
            send: true,
            info: 'success'
          });
        }
        else {
          this.setState({
            sending: false,
            send: true,
            info: 'error'
          });
        }

        setTimeout(() => {
          this.setState({
            send: false,
            info: null
          });
        }, 10000);
      });
  };

  render() {
    const {
      getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
    } = this.props.form;

    const fullnameError = isFieldTouched('fullname') && getFieldError('fullname');
    const emailError = isFieldTouched('email') && getFieldError('email');
    const phoneError = isFieldTouched('phone') && getFieldError('phone');
    const messageError = isFieldTouched('message') && getFieldError('message');

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item validateStatus={fullnameError ? 'error' : ''} help={fullnameError || ''}>
            {getFieldDecorator('fullname', {
              rules: [{ required: true, message: 'Entrez votre nom et prénom' }],
            })(
              <Input prefix={<Icon type="user"/>} placeholder="*Nom et prénom"/>
            )}
          </Form.Item>
          <Form.Item validateStatus={emailError ? 'error' : ''} help={emailError || ''}>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Entrez votre email' }],
            })(
              <Input prefix={<Icon type="mail"/>} placeholder="*Email"/>
            )}
          </Form.Item>
          <Form.Item validateStatus={phoneError ? 'error' : ''} help={phoneError || ''}>
            {getFieldDecorator('phone', {
              rules: [{ message: 'Entrez votre téléphone' }],
            })(
              <Input prefix={<Icon type="phone"/>} placeholder="Téléphone"/>
            )}
          </Form.Item>
          <Form.Item validateStatus={messageError ? 'error' : ''} help={messageError || ''}>
            {getFieldDecorator('message', {
              rules: [{ required: true, message: 'Entrez votre message' }],
            })(
              <Input.TextArea placeholder="*Message"/>
            )}
          </Form.Item>
          <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())} loading={this.state.sending}>Envoyer</Button>
          </Form.Item>
        </Form>

        {
          this.state.send && this.state.info === 'success' && (
            <Alert message="Merci d'avoir pris contact avec nous ! Nous vous répondrons dans les plus brefs délais." type="success"/>
          )
        }

        {
          this.state.send && this.state.info === 'error' && (
            <Alert message="Erreur lors de l'envoi du message, merci de réessayer." type="error" />
          )
        }
      </React.Fragment>
    )
  }
}

export default Form.create({ name: 'contact_form' })(ContactForm);
